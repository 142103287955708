<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" :title="title" fixed>
      <fb-button
        slot="left"
        icon="back"
        @click="backToNative()"
        size="small"
        type="primary"
      >
        返回
      </fb-button>
      <div slot="right">
        <div class="header-menu">
          <div class="icon" @click="onSearch">
            <van-icon name="search" />
          </div>
          <div class="icon" @click="onAdd">
            <van-icon name="plus" />
          </div>
        </div>
      </div>
    </fb-header>
    <join-organization v-if="!this.organizationId" />
    <van-popup v-model="searchVisible" position="top" get-container="body">
      <van-field
        left-icon="search"
        v-model="keyword"
        placeholder="检索发票抬头、案件名称"
        @input="searchInput"
        ref="inputsearch"
      />
    </van-popup>
    <div class="oa-invoice">
      <filter-dropdown
        :filters="filters"
        @select="handleFilterSelect"
        @dateChange="handleFilterDateChange"
      />
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="!isRequest"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="onLoad"
        >
          <div class="oa-case-list">
            <div
              class="oa-case"
              v-for="(item, index) in invoiceList"
              :key="index"
              @click="jump(item, index)"
            >
              <div class="oa-case-header">
                <div class="oa-case-money">金额：{{ item.money }}元</div>
                <div class="oa-case-userInfo">
                  <img :src="item.avatarUrl" alt="" />
                  <div>{{ item.applicantName }}</div>
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'UNSUBMENT'"
                >
                  未提交
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'UNAUDITED'"
                >
                  待审核
                </div>
                <div class="oa-case-status" v-if="item.progress === 'APPROVED'">
                  审核通过
                </div>
                <div
                  class="oa-case-status"
                  v-if="item.progress === 'CERTIFIED_FAILED'"
                >
                  审核不通过
                </div>
              </div>
              <div class="oa-case-center">
                <div>{{ item.caseName }}</div>
              </div>
              <div class="oa-case-bottom">
                <div class="oa-case-time">申请时间：{{ item.useTime }}</div>
                <div class="oa-case-long">{{ item.useTimeText }}</div>
                <div class="oa-case-btn">
                  <button
                    v-for="(btnItem, btnIndex) in item.btnList"
                    :key="btnIndex"
                    @click.stop="btnClick(btnItem, item, index)"
                  >
                    {{ btnItem }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </fb-page>
</template>

<script>
import goBackMixin from '@mixins/goBack'
import { mapState, mapActions, mapMutations } from 'vuex'
import FilterDropdown from '../../components/filterDropdown'
import JoinOrganization from '@/components/join-organization'

export default {
  name: 'oa-invoice',
  mixins: [goBackMixin],
  components: {
    FilterDropdown,
    JoinOrganization
  },
  data() {
    return {
      title: '开票查询',
      searchVisible: false, // 搜索弹框
      filters: [
        {
          label: '开票类型',
          id: 'type',
          options: [
            {
              value: 'VALUE_ADDED_TAX',
              text: '增值税普通发票'
            },
            {
              value: 'VAT_SPECIAL',
              text: '增值税专用发票'
            }
          ]
        },
        {
          label: '状态',
          id: 'status',
          options: [
            {
              text: '未提交',
              value: 'UNSUBMENT'
            },
            {
              text: '待审核',
              value: 'UNAUDITED'
            },
            {
              text: '审核通过',
              value: 'APPROVED'
            },
            {
              text: '审核不通过',
              value: 'CERTIFIED_FAILED'
            }
          ]
        },
        {
          label: '创建时间',
          id: 'date',
          options: []
        }
      ],
      invoiceList: [], // 发票列表
      page: 0, // 起始页
      startTime: '', // 开始时间
      endTime: '', // 结束时间
      keyword: '', // 关键词
      progress: '',
      type: '',
      isRequest: true, // 是否可以继续请求
      loading: true, // 是否处于加载状态
      refreshing: false // 是否处于下拉刷新状态
    }
  },
  computed: {
    ...mapState('user', ['organizationId', 'userInfo']),
    ...mapState('oa', ['oaTab'])
  },
  methods: {
    ...mapActions('user', ['getOrganizationId']),
    ...mapMutations('oa', ['setOaTab']),
    // 搜索按钮点击事件
    onSearch() {
      this.searchVisible = true
      setTimeout(() => {
        this.$refs.inputsearch.focus()
      }, 100)
    },
    // 添加按钮点击事件
    onAdd() {
      this.$router.push({
        name: 'oa-addInvoice',
        query: {
          invoice: '',
          isAdd: 0 // 0表示新增
        }
      })
    },
    // #筛选器# 选择处理
    handleFilterSelect(payload) {
      console.log('handleFilterSelect', payload)
      switch (payload.category.id) {
        case 'type':
          if (this.type === payload.option.value) {
            this.type = ''
          } else {
            this.type = payload.option.value
          }
          break
        case 'status':
          if (this.progress === payload.option.value) {
            this.progress = ''
          } else {
            this.progress = payload.option.value
          }
          break
      }
      this.invoiceList = []
      this.page = 0
      this.isRequest = true
      this.getInvoice()
    },
    /* #筛选器-日期选择器# 改变处理
     * @param payload date类型
     */
    handleFilterDateChange(payload) {
      console.log('handleFilterDateChange', payload)
      this.invoiceList = []
      this.startTime = payload[0]
      this.endTime = payload[1]
      this.page = 0
      this.isRequest = true
      this.getInvoice()
    },
    // 获取开票记录
    getInvoice() {
      this.loading = true
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const params = {
        userId: this.userInfo.id,
        keyword: this.keyword,
        startTime: this.startTime,
        endTime: this.endTime,
        page: this.page,
        progress: this.progress,
        type: this.type
      }
      this.$axios
        .get(`${this.$base}/management/invoice/record`, {
          params: params
        })
        .then(res => {
          if (res.data.data) {
            if (res.data.data.totalElements === 0) {
              // 无数据显示缺省页面
              this.invoiceList = res.data.data.content
              this.isRequest = false
            } else {
              if (res.data.data.content.length > 0) {
                for (var i = 0; i < res.data.data.content.length; i++) {
                  let avatarUrl = ''
                  let applicantName = ''
                  res.data.data.content[i].approveRecordUsers.map((item, i) => {
                    if (item.type === 'APPLICANT') {
                      avatarUrl = item.user.avatarUrl
                      applicantName = item.user.nickName
                    }
                  })
                  res.data.data.content[i].avatarUrl = avatarUrl
                  res.data.data.content[i].applicantName = applicantName
                  // 申请时间
                  res.data.data.content[i].useTime = this.timestampToTime(
                    res.data.data.content[i].createTime
                  )
                  res.data.data.content[i].useTimeText = this.getDateDiff(
                    res.data.data.content[i].createTime
                  )
                  switch (res.data.data.content[i].progress) {
                    case 'UNSUBMENT':
                      res.data.data.content[i].btnList = ['申请', '删除']
                      break
                    case 'UNAUDITED':
                      for (
                        var j = 0;
                        j < res.data.data.content[i].approveRecordUsers.length;
                        j++
                      ) {
                        if (
                          res.data.data.content[i].approveRecordUsers[j]
                            .type === 'APPROVER'
                        ) {
                          if (
                            res.data.data.content[i].approveRecordUsers[j].user
                              .id === this.userInfo.id
                          ) {
                            res.data.data.content[i].btnList = [
                              '批准',
                              '驳回'
                            ]
                            break
                          } else {
                            res.data.data.content[i].btnList = []
                          }
                        }
                      }
                      break
                    case 'APPROVED':
                      res.data.data.content[i].btnList = []
                      break
                    case 'CERTIFIED_FAILED':
                      res.data.data.content[i].btnList = ['申请', '删除']
                      break
                  }
                }
                this.invoiceList = this.invoiceList.concat(
                  res.data.data.content
                )
              }
              if (this.page + 1 >= res.data.data.totalPages) {
                this.isRequest = false
              } else {
                this.isRequest = true
              }
            }
          } else {
            this.isRequest = false
          }
          this.loading = false
          this.refreshing = false
          this.$toast.clear()
        })
    },

    // 申请时间格式化
    getDateDiff(dateTimeStamp) {
      var result = ''
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var now = new Date().getTime()
      var diffValue = now - dateTimeStamp
      var dayC = diffValue / day
      var hourC = diffValue / hour
      var minC = diffValue / minute
      if (dayC >= 1) {
        result = '' + parseInt(dayC) + '天前'
      } else if (hourC >= 1) {
        result = '' + parseInt(hourC) + '小时前'
      } else {
        result = '' + parseInt(minC) + '分钟前'
      }
      return result
    },

    // 时间戳转换成时间
    timestampToTime(cjsj) {
      var date = new Date(cjsj) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-'
      var M =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var h =
        ' ' +
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
        ':'
      var m =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return Y + M + D + h + m
    },

    // 上拉加载事件
    onLoad() {
      this.page++
      this.loading = true
      this.getInvoice()
    },

    // 下拉刷新
    onRefresh() {
      this.invoiceList = []
      this.page = 0
      this.isRequest = true
      this.getInvoice()
    },

    // 搜索功能
    searchInput() {
      this.invoiceList = []
      this.page = 0
      this.isRequest = true
      this.getInvoice()
    },

    /**
     * 按钮点击事件
     * name 操作名称
     * item 数据内容
     * index 第几条数据
     */
    btnClick(name, item, index) {
      if (name === '删除') {
        this.$dialog
          .confirm({
            title: '提示',
            message: '删除不可恢复！确定删除吗？'
          })
          .then(async () => {
            // on confirm
            const response = await this.$axios.post(
              `${this.$base}/management/invoice/record/delete/${item.id}`
            )
            const { code } = response.data
            if (code === 200) {
              this.$toast.success('删除成功')
              this.invoiceList.splice(index, 1)
            }
          })
          .catch(() => {
            // on cancel
          })
      } else if (name === '批准' || name === '驳回') {
        var obj = JSON.parse(JSON.stringify(item))
        obj.progress = name === '批准' ? 'APPROVED' : 'CERTIFIED_FAILED'
        obj.useTime = new Date(item.useTime).getTime()
        this.$axios
          .put(
            `${this.$base}/management/invoice/record/${item.id}/progress`,
            obj
          )
          .then(res => {
            if (res.data.code === 200) {
              this.$toast.success('提交成功')
              setTimeout(() => {
                this.onRefresh()
              }, 1000)
              // this.$router.go(-1) // 返回上一层
            } else {
              // this.$message.error(res.data.message)
            }
          })
      } else if (name === '申请') {
        var obj2 = JSON.parse(JSON.stringify(item))
        obj2.progress = 'UNAUDITED'
        obj2.useTime = new Date().getTime()
        this.$axios
          .put(
            `${this.$base}/management/invoice/record/${item.id}/progress`,
            obj2
          )
          .then(res => {
            if (res.data.code === 200) {
              this.$toast.success('提交成功')
              setTimeout(() => {
                this.onRefresh()
              }, 1000)

              // this.$router.go(-1) // 返回上一层
            } else {
              // this.$message.error(res.data.message)
            }
          })
      } else {
        this.jump(item, index)
      }
    },

    // 跳转事件
    jump(item, index) {
      this.$router.push({
        name: 'oa-addInvoice',
        query: {
          commonId: item.id,
          // invoice: JSON.stringify(item),
          isAdd: 1 // 1表示修改
        }
      })
    },

    // 获取组织id
    async getId() {
      await this.getOrganizationId()
      if (!this.organizationId) {
        return this.$bus.$emit('join-org', { show: true })
      }
    }
  },
  async created() {
    await this.getId()
  },
  mounted() {
    this.getInvoice()
  },
  destroyed() {
    this.setOaTab('')
  }
}
</script>

<style lang="stylus">
.oa-invoice
  position relative
  & .oa-case-list
    padding 0 10px
    box-sizing border-box
    min-height 100%
    & .oa-case
      border-radius 5px
      border 1px solid rgba(204, 204, 204, 1)
      width 100%
      height auto
      padding 10px 0
      box-sizing border-box
      margin-bottom 10px
      & .oa-case-header
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        padding 0 10px
        box-sizing border-box
      & .oa-case-title
        font-size 14px
        color #666666
      & .oa-case-money
        font-size 16px
        font-weight 700
      & .oa-case-userInfo
        font-size 14px
        display flex
        flex-direction row
        align-items center
        img
          width 24px
          height 24px
          margin-right 5px
      & .oa-case-status
        font-size 16px
      & .oa-case-center
        font-size 16px
        font-weight bold
        box-sizing border-box
        padding 10px 15px
        border-bottom 1px solid rgba(204, 204, 204, 1)
        div
          display -webkit-box
          -webkit-box-orient vertical
          -webkit-line-clamp 2
          overflow hidden
      & .oa-case-bottom
        display flex
        flex-direction row
        align-items center
        box-sizing border-box
        padding 10px 10px 0 10px
        & .oa-case-time
          font-size 12px
          color #999999
          margin-right 10px
        & .oa-case-btn
          display flex
          flex-direction row
          align-items center
          justify-content flex-end
          flex 1
          button
            width 55px
            height 30px
            border-radius 5px
            border 1px solid rgba(0, 121, 254, 1)
            background none
            color #0079FE
            font-size 14px
</style>
